<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>

        <template v-slot:right>
          <div class="p-inputgroup">
            <InputText
              v-model.trim="keyword"
              required="true"
              :class="{ 'p-invalid': submitted && !keyword }"
              placeholder="Search"
            />
            <Button
              icon="pi pi-search "
              class="p-button-primary p-mr-1"
              @click="loadSearchData"
            />
          </div>
          <Button
            icon="pi pi-plus"
            class="p-mr-2 p-button-success"
            @click="openDialog"
          />
        </template>
      </Toolbar>
      <div class="p-mt-2">
        <DataTable
          :value="sortStores"
          :lazy="true"
          :loading="loading"
          :totalRecords="totalRecords"
          :scrollable="true"
          scrollDirection="horizontal"
          scrollHeight="flex"
          @page="onPage($event)"
          class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        >
          <template #empty>
            <div class="p-text-center p-p-3">No records found</div>
          </template>
          <Column
            field="service_date"
            headerStyle="width: 150px"
            header="Opening Date"
          >
          </Column>
          <Column
            headerStyle="width: 150px"
            header="Type"
          >
            <template #body="slotProps">
              {{convertName(slotProps.data.is_admin)}}
            </template>
          </Column>
          <Column
            field="code"
            headerStyle="width: 150px"
            header="Store Code"
          ></Column>
          <Column
            field="mall"
            headerStyle="width: 250px"
            header="Mall"
          ></Column>
          <Column
            field="telephone"
            headerStyle="width: 150px"
            header="Telephone"
          ></Column>
          <Column
            field="address"
            headerStyle="width: 300px"
            header="Address"
          ></Column>
          <Column
            field="city"
            headerStyle="width: 150px"
            header="City"
          ></Column>
          <Column
            field="postalcode"
            headerStyle="width: 150px"
            header="Postal Code"
          ></Column>
          <Column
            :exportable="false"
            headerStyle="width: 150px"
            header="Action"
          >
            <template #body="slotProps">
              <span class="p-buttonset">
                <Button
                  class="p-button-primary p-button-rounded"
                  icon="pi pi-pencil"
                  @click="editIem(slotProps.data)"
                />
                <Button
                  class="p-button-success p-button-rounded"
                  v-if="slotProps.data.status == 'Active'"
                  @click="confirmChangeStatus(slotProps.data, 'Inactive')"
                  icon="pi pi-eye-slash"
                />
                <Button
                  class="p-button-danger p-button-rounded"
                  v-else-if="slotProps.data.status == 'Inactive'"
                  @click="confirmChangeStatus(slotProps.data, 'Active')"
                  icon="pi pi-eye"
                />
                <Button
                  class="p-button-warning p-button-rounded"
                  @click="changePin(slotProps.data)"
                  icon="pi pi-key"
                />
              </span>
            </template>
          </Column>
        </DataTable>
      </div>

      <Dialog
        v-model:visible="productDialog"
        :style="{ width: '100vw' }"
        :maximizable="true"
        position="top"
         :modal="true"
        class="p-fluid"
      >
        <template #header>
          <h4 class="p-dialog-titlebar p-dialog-titlebar-icon">
            {{ dialogTitle }}
          </h4>
        </template>
        <div class="p-formgrid p-grid">
          <div class="p-field p-col">
            <b> <i class="pi pi-th-large"></i> Store Details</b>
          </div>
          <div class="p-field p-col">
            <b> <i class="pi pi-th-large"></i> Other Store Details</b>
          </div>
          <div class="p-field p-col">
            <b><i class="pi pi-th-large"></i> Store Paycodes</b>
          </div>
        </div>
        <div class="p-formgrid p-grid">
          <div class="p-field p-col">
            <label for="StoreType">Store Type:</label>
            <Dropdown
              id="StoreType"
              v-model="branchDetails.isAdmin"
              :options="storeTypes"
              optionLabel="name"
              placeholder="e.g Store"
            />
          </div>
          <div class="p-field p-col">
            <label for="CostCenter">Cost Center:</label>
            <InputText
              id="CostCenter"
              v-model="branchDetails.costCenter"
              placeholder="e.g 8003"
            />
          </div>
          <div class="p-field p-col">
            <label for="Overtime">Overtime:</label>
            <InputNumber
              id="Overtime"
              mode="currency"
              v-model="branchDetails.overTime"
              currency="USD"
              locale="en-US"
            />
          </div>
        </div>
        <div class="p-formgrid p-grid">
          <div class="p-field p-col">
            <label for="Code">Code:</label>
            <InputNumber
              id="Code"
              v-model="branchDetails.storeCode"
              placeholder="e.g 60"
              :useGrouping="false"
              :class="{ 'p-invalid': submitted && !branchDetails.storeCode }"
            />
            <small
              class="p-invalid"
              v-if="submitted && !branchDetails.storeCode"
              >Code is required.</small
            >
          </div>
          <div class="p-field p-col">
            <label for="RegularHoursWeekly">Regular Hours Weekly:</label>
            <InputNumber
              id="RegularHoursWeekly"
              v-model="branchDetails.regularWeeklyHrs"
              :useGrouping="false"
              :class="{
                'p-invalid': submitted && !branchDetails.regularWeeklyHrs,
              }"
              intergeronly
            />
            <small
              class="p-invalid"
              v-if="submitted && !branchDetails.regularWeeklyHrs"
              >Regular Weekly Hours is required.</small
            >
          </div>
          <div class="p-field p-col">
            <label for="Birthday">Birthday :</label>
            <InputNumber
              id="Birthday"
              mode="currency"
              v-model="branchDetails.birthDay"
              currency="USD"
              locale="en-US"
            />
          </div>
        </div>
        <div class="p-formgrid p-grid">
          <div class="p-field p-col">
            <label for="MallName">Mall Name:</label>
            <InputText
              id="MallName"
              v-model="branchDetails.mallName"
              placeholder="e.g Regant Mall"
              :class="{ 'p-invalid': submitted && !branchDetails.mallName }"
            />
            <small class="p-invalid" v-if="submitted && !branchDetails.mallName"
              >Mall Name is required.</small
            >
          </div>
          <div class="p-field p-col">
            <label for="RegularHoursDaily">Regular Hours Daily:</label>
            <InputNumber
              id="RegularHoursDaily"
              v-model="branchDetails.regularDailyHrs"
              :useGrouping="false"
              :class="{
                'p-invalid': submitted && !branchDetails.regularDailyHrs,
              }"
              integeronly
            />
            <small
              class="p-invalid"
              v-if="submitted && !branchDetails.regularWeeklyHrs"
              >Regular Daily Hours is required.</small
            >
          </div>
          <div class="p-field p-col">
            <label for="StatHol">Stat Hol :</label>
            <InputNumber
              id="StatHol"
              mode="currency"
              v-model="branchDetails.statHoliday"
              currency="USD"
              locale="en-US"
            />
          </div>
        </div>
        <div class="p-formgrid p-grid">
          <div class="p-field p-col">
            <label for="TelephoneNo">Telephone No:</label>
            <InputText
              id="TelephoneNo"
              v-model="branchDetails.telephoneNo"
              placeholder="e.g 5064518816"
            />
          </div>
          <div class="p-field p-col">
            <label for="StatHolidayRate">Stat Holiday Rate:</label>
            <InputNumber
              id="StatHolidayRate"
              v-model="branchDetails.statholidayRate"
              :class="{
                'p-invalid': submitted && !branchDetails.statholidayRate,
              }"
              prefix="%"
              mode="decimal"
              :minFractionDigits="1"
              :maxFractionDigits="2"
              :min="0"
              :max="100"
              :useGrouping="false"
            />
            <small
              class="p-invalid"
              v-if="submitted && !branchDetails.statholidayRate"
              >Stat Holiday Rate is required.</small
            >
          </div>
          <div class="p-field p-col">
            <label for="Statwork">Statwork:</label>
            <InputNumber
              id="Statwork"
              v-model="branchDetails.statWork"
              mode="currency"
              currency="USD"
              locale="en-US"
            />
          </div>
        </div>
        <div class="p-formgrid p-grid">
          <div class="p-field p-col">
            <label for="Contact">Contact No:</label>
            <InputText
              id="Contact"
              v-model="branchDetails.contactNo"
              placeholder="e.g Rediat Kiros"
            />
          </div>
          <div class="p-field p-col">
            <label for="OvertimeRate">Overtime Rate:</label>
            <InputNumber
              id="OvertimeRate"
              v-model="branchDetails.overTimeRate"
              prefix="%"
              mode="decimal"
              :class="{ 'p-invalid': submitted && !branchDetails.overTimeRate }"
              :minFractionDigits="1"
              :maxFractionDigits="2"
              :min="0"
              :max="100"
              :useGrouping="false"
            />
            <small
              class="p-invalid"
              v-if="submitted && !branchDetails.overTimeRate"
              >Overtime Rate is required.</small
            >
          </div>
          <div class="p-field p-col">
            <label for="MeetCon">MeetCon:</label>
            <InputNumber
              id="MeetCon"
              mode="currency"
              v-model="branchDetails.meetCon"
              currency="USD"
              locale="en-US"
            />
          </div>
        </div>
        <div class="p-formgrid p-grid">
          <div class="p-field p-col">
            <label for="Address">Address:</label>
            <InputText
              id="Address"
              v-model="branchDetails.address"
              placeholder="e.g 1381 Regant ST,Unit 8A"
            />
          </div>
          <div class="p-field p-col">
            <label for="StoreOpeningDate">Store Opening Date:</label>
            <InputMask
              id="StoreOpeningDate"
              mask="9999-99-99"
              v-model="branchDetails.storeOpeningDate"
              placeholder="9999-99-99"
              slotChar="yyyy-mm-dd"
            />
          </div>
          <div class="p-field p-col">
            <label for="Personal">Personal :</label>
            <InputNumber
              id="Personal"
              mode="currency"
              v-model="branchDetails.personal"
              currency="USD"
              locale="en-US"
            />
          </div>
        </div>
        <div class="p-formgrid p-grid">
          <div class="p-field p-col">
            <label for="City">City:</label>
            <InputText
              id="City"
              v-model="branchDetails.city"
              placeholder="e.g Fredericton"
            />
          </div>
          <div class="p-field p-col">
            <label for="Sqft">Sqft:</label>
            <InputNumber
              id="Sqft"
              v-model="branchDetails.sqft"
              :useGrouping="false"
              intergeronly
            />
          </div>
          <div class="p-field p-col">
            <label for="SickHrs">Sick Hrs :</label>
            <InputNumber
              id="SickHrs"
              v-model="branchDetails.sickHrs"
              mode="currency"
              currency="USD"
              locale="en-US"
            />
          </div>
        </div>
        <div class="p-formgrid p-grid">
          <div class="p-field p-col">
            <label for="Region">Region:</label>
            <Dropdown
              id="Region"
              v-model="branchDetails.region"
              :options="regionsList"
              optionLabel="region_name"
              optionValue="region_id"
              :filter="true"
            />
          </div>
          <div class="p-field p-col-4">
            <label for="enable_email_sms">Enable SMS/Email :</label>
            <Dropdown
              v-model="branchDetails.enableSms"
              id="enable_email_sms"
              :options="smsOptions"
              optionLabel="name"
            />
          </div>
          <div class="p-field p-col">
            <label for="Vacation">Vacation :</label>
            <InputNumber
              id="Vacation"
              v-model="branchDetails.vacations"
              mode="currency"
              currency="USD"
              locale="en-US"
            />
          </div>
        </div>
        <div class="p-formgrid p-grid">
          <div class="p-field p-col">
            <label for="PostalCode">Postal Code:</label>
            <InputText
              id="PostalCode"
              v-model="branchDetails.postalCode"
              placeholder="e.g E3C 1A2  "
            />
          </div>
          <div class="p-field p-col-4">
            <label for="Store_Magic">Store Unity Number :</label>
            <InputNumber
              id="Store_Magic"
              v-model="branchDetails.storeMagic"
              mode="currency"
              currency="USD"
              locale="en-US"
            />
          </div>
          <div class="p-field p-col">
            <label for="Beravmt">Beravmt:</label>
            <InputNumber
              id="Beravmt"
              mode="currency"
              v-model="branchDetails.breavmt"
              currency="USD"
              locale="en-US"
            />
          </div>
        </div>
        <div class="p-formgrid p-grid">
          <div class="p-field p-col">
            <label for="LL">LL:</label>
            <InputText
              id="LL"
              v-model="branchDetails.ll"
              placeholder="e.g Primaris"
            />
          </div>
          <div class="p-field p-col-4">
            <div v-if="dialogTitle != 'Update Store'">
              <label>Password Default 12345:</label>
              <Password v-model="branchDetails.password" />
            </div>
          </div>
          <div class="p-field p-col">
            <label for="Training">Training:</label>
            <InputNumber
              id="Training"
              v-model="branchDetails.training"
              mode="currency"
              currency="USD"
              locale="en-US"
            />
          </div>
         
        </div>
        <div class="p-formgrid p-grid">
          <div class="p-field p-col-4">
            <label for="Emailaddress">Email address:</label>
            <InputText
              id="Emailaddress"
              v-model="branchDetails.emailAddress"
              placeholder="e.g info@stitchit.com"
            />
          </div>
           <div class="p-field p-col-4">
            <label for="TaxNumber">Tax Number:</label>
            <InputText
              id="TaxNumber"
              v-model="branchDetails.taxNo"
              placeholder=""
            />
          </div>
           <div class="p-field p-col-4">
            <label for="qrCodeTagLine">Qr Tag Line:</label>
            <InputText
              id="qrCodeTagLine"
              v-model="branchDetails.qrCodeTagLine"
              placeholder=""
            />
          </div>
        </div>
        <div class="p-formgrid p-grid">
          <div class="p-field p-col-4">
            <label for="qrCodeLink">Qr Code Link:</label>
            <InputText
              id="qrCodeLink"
              v-model="branchDetails.qrCodeLink"
              placeholder=""
            />
          </div>
          <div class="p-field p-col-4">
            <label for="Province">Province:</label>
            <Dropdown
              id="Province"
              v-model="branchDetails.province"
              :options="provinceList"
              optionLabel="key"
              optionValue="key"
              :filter="true"
            />
          </div>
          <div class="p-field p-col">
            <label for="breakTimingsDaily">Break after consecutive working hours:</label>
            <InputNumber
              id="breakTimingsDaily"
              v-model="branchDetails.breakHrs"
              :useGrouping="false"
              :class="{
                'p-invalid': submitted && !branchDetails.breakHrs,
              }"
              mode="decimal"
              :maxFractionDigits="2"
              :minFractionDigits="2"
            />
            <small
              class="p-invalid"
              v-if="submitted && !branchDetails.breakHrs"
              >Break Hours is required.</small
            >
          </div>
        </div>
         <h3>Taxing:</h3>
        <div class="p-grid">
             <div class="p-field p-col-3">
              <label for="TaxCriteria">Federal Tax:</label>
              <Dropdown
                v-model="branchDetails.taxCriteria1"
                id="taxCriteria1"
                :options="taxCriteria"
                @change="taxCriteriaSetting1"
                optionLabel="name"
                optionValue="name"
              />
            </div>
            <div class="p-field p-col-3">
              <label for="tax1">Tax Value (%):</label>
              <InputNumber
                :disabled="branchDetails.taxCriteria1 == 'None'"
                :maxFractionDigits="3"
                :minFractionDigits="3"
                id="tax1"
                v-model="branchDetails.taxValue1"
              />
            </div>
            <div class="p-field p-col-3">
              <label for="taxCriteria2">Provincial Tax:</label>
              <Dropdown
                v-model="branchDetails.taxCriteria2"
                id="taxCriteria2"
                :options="taxCriteria"
                @change="taxCriteriaSetting2"
                optionLabel="name"
                optionValue="name"
              />
            </div>
            <div class="p-field p-col-3">
              <label for="tax2">Tax Value (%):</label>
              <InputNumber
               :disabled="branchDetails.taxCriteria2 == 'None'"
                :maxFractionDigits="3"
                :minFractionDigits="3"
                id="tax2"
                v-model="branchDetails.taxValue2"
              />
            </div>
        </div>
        <template #footer>
          <Button
            type="submit"
            label="Save"
            icon="pi pi-check"
            class="p-button-primary"
            @click.prevent="saveItem"
          />
        </template>
      </Dialog>

      <Dialog
        v-model:visible="statusDialog"
        :style="{ width: '450px' }"
        header="Confirm"
      >
        <div class="confirmation-content">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span v-if="branchDetails"
            >Change the status of <b>{{ branchDetails.mallName }}</b> to
            {{ productStatus }}?</span
          >
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text"
            @click="statusDialog = false"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            class="p-button-text"
            @click="changeStatus"
          />
        </template>
      </Dialog>
      <StorePin
        :pinDialogStatus="pinDialogStatus"
        :storeCode="storeCode"
        v-on:changeDialogStatus="updateThePinDialogStatus"
      />
    </div>
  </section>
</template>
<script lang="ts">
import { Options , mixins } from "vue-class-component";
import Branch from "../../service/Branch";
import Toaster from "../../helpers/Toaster";
import StorePin from "../../components/StorePin.vue";
import UtilityOptions from "../../mixins/UtilityOptions";

interface IStore {
  address: string;
  bereavmt: string;
  birhtday: string;
  branch_password: string;
  city: string;
  code: number;
  contact: string;
  cost_center: string;
  country: string;
  created_date: string;
  daily_hrs: number;
  date: string;
  default_schedule: string;
  email_address: string;
  enable_email_sms: string;
  gst: number;
  is_admin: string;
  ll: string;
  mall: string;
  meetcon: number;
  overtime: number;
  overtime_rate: number;
  personel: number;
  postalcode: string;
  province: string;
  pst: number;
  rating_desc: string;
  rating_link: string;
  region: string;
  region_code: string;
  region_name: string;
  regular_hrs: number;
  service_date: string;
  sickhrs: number;
  sqft: string;
  stat_hol:number;
  stat_holiday_rate:number;
  status:string;
  statwork:number;
  store_magic:number;
  tax_code: string;
  tax_name: string;
  telephone: string;
  training: number;
  vacations: number;
}

@Options({
  components: {
    StorePin,
  },
})
export default class Branches extends  mixins(UtilityOptions){
  private lists: IStore [] = [];
  private branches;
  private productStatus = "";
  private keyword = "";
  private loading = false;
  private productDialog = false;
  private statusDialog = false;
  private checkPagination = true;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Stores" },
  ];
  private storeTypes = [
    {
      value: "HQ",
      name: "HQ",
    },
    {
      value: "Branch",
      name: "Store",
    },
  ];
  private taxCriteria = [
    {
      name: "None",
    },
    {
      name: "GST",
    },
    {
      name: "PST",
    },
    {
      name: "HST",
    },
    {
      name: "QST",
    },
    {
      name: "SALES TAX",
    },
  ];

  private smsOptions = [
    {
      value: "enable",
      name: "Enable",
    },
    {
      value: "disable",
      name: "Disable",
    },
  ];

  private toast;

  private provinceList = [
    { key: "Nunavut" },
    { key: "Quebec" },
    { key: "Northwest Territories" },
    { key: "Ontario" },
    { key: "British Columbia" },
    { key: "Alberta" },
    { key: "Saskatchewan" },
    { key: "Yukon" },
    { key: "Newfoundland and Labrador" },
    { key: "New Brunswick" },
    { key: "Nova Scotia" },
    { key: "Alabama" },
    { key: "Alaska" },
    { key: "Arizona" },
    { key: "Arkansas" },
    { key: "California" },
    { key: "Colorado" },
    { key: "Connecticut" },
    { key: "Delaware" },
    { key: "Florida" },
    { key: "Georgia" },
    { key: "Hawaii" },
    { key: "Idaho" },
    { key: "Illinois" },
    { key: "Indiana" },
    { key: "Iowa" },
    { key: "Kansas" },
    { key: "Kentucky" },
    { key: "Louisiana" },
    { key: "Maine" },
    { key: "Maryland" },
    { key: "Massachusetts" },
    { key: "Michigan" },
    { key: "Minnesota" },
    { key: "Manitoba" },
    { key: "Mississippi" },
    { key: "Missouri" },
    { key: "Montana" },
    { key: "Nebraska" },
    { key: "Nevada" },
    { key: "New Hampshire" },
    { key: "New Jersey" },
    { key: "New Mexico" },
    { key: "New York" },
    { key: "North Carolina" },
    { key: "North Dakota" },
    { key: "Ohio" },
    { key: "Oklahoma" },
    { key: "Oregon" },
    { key: "Pennsylvania" },
    { key: "Rhode Island" },
    { key: "South Carolina" },
    { key: "South Dakota" },
    { key: "Tennessee" },
    { key: "Texas" },
    { key: "Utah" },
    { key: "Vermont" },
    { key: "Virginia" },
    { key: "Washington" },
    { key: "West Virginia" },
    { key: "Wisconsin" },
    { key: "Wyoming" },
  ];


  private branchDetails = {
    storeID: 0,
    isAdmin: {},
    storeCode: 0,
    mallName: "",
    telephoneNo: "",
    contactNo: "",
    address: "",
    city: "",
    region: "",
    province: "",
    country: "",
    postalCode: "",
    ll: "",
    costCenter: "",
    regularWeeklyHrs: 44,
    breakHrs: 5,
    regularDailyHrs: 8,
    statholidayRate: 0,
    overTimeRate: 0,
    storeOpeningDate: "",
    sqft: 80,
    taxCriteria1: "None",
    taxCriteria2: "None",
    taxValue1: 0,
    taxValue2: 0,
    emailAddress: "",
    password: "12345",
    taxNo: "",
    overTime: 0,
    birthDay: 0,
    statHoliday: 0,
    statWork: 0,
    meetCon: 0,
    personal: 0,
    sickHrs: 0,
    vacations: 0,
    breavmt: 0,
    training: 0,
    storeMagic: 0,
    enableSms: {},
    status: "",
    qrCodeLink: "",
    qrCodeTagLine: "",
  };

  private dialogTitle;
  private dialogCallback;
  private regionsList;
  private submitted = false;
  private pinDialogStatus = false;
  private storeCode = 0;

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.branches = new Branch();
    this.toast = new Toaster();
  }

  //OPNE DIALOG TO CHANGE STORE PIN
  changePin(data) {
    this.pinDialogStatus = true;
    this.storeCode = Number(data.code);
  }

  //USED TO UPDATE THE PIN STATUS
  updateThePinDialogStatus() {
    this.pinDialogStatus = false;
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.clearItem();

    this.submitted = false;
    this.dialogTitle = "Add New Store";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem() {
    this.submitted = true;
    if (this.branchDetails.mallName.trim()) {
      if (this.branchDetails.storeID != 0) {
        this.branches.updateItem(this.branchDetails).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.branches.saveItem(this.branchDetails).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    
    this.submitted = false;
    this.dialogTitle = "Update Store";
    this.productDialog = true;
    this.branches.getItem(data).then((res) => {
      if (res.length > 0) {
        res = res[0];

        const isAdmin = this.filterStoreType(res);
        const smsEnabled = this.filterSmsEnabled(res);

        this.branchDetails = {
          isAdmin: isAdmin,
          storeID: Number(res.code),
          storeCode: Number(res.code),
          mallName: res.mall,
          telephoneNo: res.telephone,
          contactNo: res.contact,
          address: res.address,
          city: res.city,
          region: res.region,
          province: res.province,
          country: "",
          postalCode: res.postalcode,
          ll: res.ll,
          costCenter: res.cost_center,
          breakHrs: Number(res.break_hrs),
          regularWeeklyHrs: Number(res.regular_hrs),
          regularDailyHrs: Number(res.daily_hrs),
          statholidayRate: Number(res.stat_holiday_rate),
          overTimeRate: Number(res.overtime_rate),
          storeOpeningDate: res.service_date,
          sqft: Number(res.sqft),
          taxCriteria1: res.tax_criteria_1,
          taxCriteria2: res.tax_criteria_2,
          taxValue1: Number(res.tax_value_1),
          taxValue2: Number(res.tax_value_2),
          emailAddress: res.email_address,
          password: res.branch_password,
          taxNo: res.tax_code,
          overTime: Number(res.overtime),
          birthDay: Number(res.birhtday),
          statHoliday: Number(res.stat_hol),
          statWork: Number(res.statwork),
          meetCon: Number(res.meetcon),
          personal: Number(res.personel),
          sickHrs: Number(res.sickhrs),
          vacations: Number(res.vacations),
          breavmt: Number(res.bereavmt),
          training: Number(res.training),
          storeMagic: Number(res.store_magic),
          enableSms: smsEnabled,
          status: res.status,
          qrCodeLink: res.rating_link,
          qrCodeTagLine: res.rating_desc
        };
      }
    });
  }

  //USED TO FILTER TYPES
  filterStoreType(res) {
    let isAdmin = {};

    this.storeTypes.filter((elem) => {
      if (elem.value == res.is_admin) {
        isAdmin = elem;
      }
    });

    return isAdmin;
  }


  //USED TO FILTER TAX
  filterSmsEnabled(res) {
    let smsEnabled = {};
    this.smsOptions.filter((elem) => {
      if (elem.value == res.enable_email_sms) {
        smsEnabled = elem;
      }
    });

    return smsEnabled;
  }

  //USED TO FILTER TAX
  filterRegion(res) {
    let region = {};

    this.regionsList.filter((elem) => {
      if (elem.region_id == res.province) {
        region = elem;
      }
    });

    return region;
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.branchDetails = {
      isAdmin: data.is_admin,
      storeID: Number(data.code),
      storeCode: Number(data.code),
      mallName: data.mall,
      telephoneNo: data.telephone,
      contactNo: data.contact,
      address: data.address,
      city: data.city,
      region: data.region_id,
      province: data.province_name,
      country: data.country,
      postalCode: data.postalcode,
      ll: data.ll,
      costCenter: data.cost_center,
      regularWeeklyHrs: Number(data.regular_hrs),
      regularDailyHrs: Number(data.daily_hrs),
      breakHrs: Number(data.break_hrs),
      statholidayRate: Number(data.stat_holiday_rate),
      overTimeRate: Number(data.overtime_rate),
      storeOpeningDate: data.service_date,
      sqft: Number(data.sqft),
      taxCriteria1: data.tax_criteria_1,
      taxCriteria2: data.tax_criteria_2,
      taxValue1: Number(data.tax_value_1),
      taxValue2: Number(data.tax_value_2),
      emailAddress: data.email_address,
      password: data.branch_password,
      taxNo: data.tax_code,
      overTime: Number(data.overtime),
      birthDay: Number(data.birhtday),
      statHoliday: Number(data.stat_hol),
      statWork: Number(data.statwork),
      meetCon: Number(data.meetcon),
      personal: Number(data.personel),
      sickHrs: Number(data.sickhrs),
      vacations: Number(data.vacations),
      breavmt: Number(data.bereavmt),
      training: Number(data.training),
      storeMagic: Number(data.store_magic),
      enableSms: data.enable_email_sms,
      status: data.status,
      qrCodeLink: data.rating_link,
      qrCodeTagLine: data.rating_desc
    };

    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.branchDetails.status = this.productStatus;
    this.branches.changeStatus(this.branchDetails).then((res) => {
      this.loadList(0);
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.loading = true;
    this.branches.getItems(page).then((res) => {
      this.lists        = res.store_list;
      this.totalRecords = res.total_stores_count;
      this.limit        = res.limit_per_page;
      this.regionsList  = res.regions;
      this.loading      = false;
    });
  }

  loadSearchData() {
    this.submitted = true;
    if (this.keyword) {
      this.branches.getSearchedBranches(this.keyword).then((data) => {
        this.lists = data.store_list;
        this.loading = false;
        this.checkPagination = data.pagination;
      });
    }
  }

  clearItem() {
    this.branchDetails = {
      storeID: 0,
      isAdmin: {},
      storeCode: 0,
      mallName: "",
      telephoneNo: "",
      contactNo: "",
      address: "",
      city: "",
      region: "",
      province: "",
      country: "",
      postalCode: "",
      ll: "",
      costCenter: "",
      regularWeeklyHrs: 44,
      regularDailyHrs: 8,
      breakHrs: 0,
      statholidayRate: 0,
      overTimeRate: 0,
      storeOpeningDate: "",
      sqft: 80,
      taxCriteria1: "None",
      taxCriteria2: "None",
      taxValue1: 0,
      taxValue2: 0,
      emailAddress: "",
      password: "12345",
      taxNo: "",
      overTime: 0,
      birthDay: 0,
      statHoliday: 0,
      statWork: 0,
      meetCon: 0,
      personal: 0,
      sickHrs: 0,
      vacations: 0,
      breavmt: 0,
      training: 0,
      storeMagic: 0,
      enableSms: {},
      status: "",
      qrCodeLink: "",
      qrCodeTagLine: "",
    };
  }

  taxCriteriaSetting1()
  {
    this.branchDetails.taxValue1 = 0;
  }
  
  taxCriteriaSetting2()
  {
    this.branchDetails.taxValue2 = 0;  
  }

  convertName(t)
  {
    let n = t;

    if(t == 'Branch')
    {
      n = 'Store';
    }

    return n;
  }

  get sortStores()
  {
     const c = this.lists.sort((a, b) =>
        Number(a.code) < Number(b.code) ? -1 : 1
    );

    return c;
  }
}
</script>

<style scoped>
.product-image {
  width: 30px;
  height: auto;
}
</style>
