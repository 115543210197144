
import { Options, Vue } from "vue-class-component";
import { publicPath } from "../../../vue.config.js";
import { useStore } from "../../store";
import Backrooom from "../../service/Backroom";
import Toaster from "../../helpers/Toaster";
import { camelCase } from "lodash";
import moment from "moment";
import router from "../../router";

interface OrderDetails {
  dueDate: string;
  dueTime: string;
  description: string;
  id: string;
}

interface OrderItemList {
  itemName: string;
  itemQty: number;
  tag3: string;
  salesItemId: number;
  slectedItem: string;
}

interface OrderService {
  serviceName: string;
  express: string;
  workingStatus: string;
  checkStatus: string;
  image: string;
  id: string;
  dueDate: string;
  description: string;
  dueTime: string;
  itemList: OrderItemList[];
}

@Options({
  components: {},
  title: 'Backroom'
})
export default class Backroom extends Vue {
  private imagePath = "";
  private store = useStore();
  private toast;
  private backroomService;
  private path = publicPath;
  private productDialog = false;
  private prepDialog = false;
  private submitted = false;
  private prepOrder = [];
  private product = {
    itemID: "",
    pin: "",
  };

  private selectedItems: string[] = [];

  private OrderDetail: OrderDetails = {
    dueDate: "",
    dueTime: "",
    description: "",
    id: "",
  };

  private OrderServices: OrderService[] = [];
  private OrderItemLists: OrderItemList[] = [];

  created() {
    this.backroomService = new Backrooom();
    this.toast = new Toaster();
    this.imagePath = this.backroomService.getBaseURL() + "uploads/services/";
  }

  clearData() {
    this.product = {
      itemID: "",
      pin: "",
    };
  }

  get progressBar() {
    return this.store.getters.getProgressBar;
  }

  scanOrder() {
    this.clearData();
    this.productDialog = true;
  }

  prepInvoice() {
    this.clearData();
    this.prepDialog = true;
  }

  itemsAction(action) {
    const len = this.product.itemID.length;

    if (len == 14 || len == 16) {
      this.submitted = true;
      if (this.product.itemID.trim()) {
        this.backroomService.scanReceipt(this.product, action).then((res) => {
          this.productDialog = false;
          this.submitted = false;
          this.toast.handleResponse(res);
          this.clearData();
          this.redirectPage(action);
        });
      }
    } else {
      this.toast.showWarning(
        "Invalid Bar code Could not process with the scanned code"
      );
    }
  }

  numPad(key) {
    if (key == "clear") {
      this.product.pin = "";
    } else if (key == "ok") {
      alert(this.product.pin);
    } else if (key == "none") {
      //DO NOTHING
    } else {
      this.product.pin = this.product.pin + key;
    }
  }

  getPrepReceipt() {
    this.backroomService.getPrepReceipt(this.product).then((res) => {
      const receiptInfo = this.camelizeKeys(res.order_details);
      const receiptItems = this.camelizeKeys(res.order_items);

      this.OrderDetail.id = receiptInfo.id;
      this.OrderDetail.dueDate = receiptInfo.dueDate;
      this.OrderDetail.dueTime = receiptInfo.dueTime;
      this.OrderDetail.description = receiptInfo.description;
      this.OrderServices = receiptItems;
    });
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  fixLength(amount) {
    if (amount != "") {
      amount = amount.toFixed(2);
    }

    return amount;
  }

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm").format("hh:mm A");
    }
  }

  addItem(serviceID, ItemsID) {
    const id = serviceID + "_" + ItemsID;

    if (this.selectedItems.includes(id)) {
      this.selectedItems.splice(this.selectedItems.indexOf(id), 1);
    } else {
      this.selectedItems.push(id);
    }
  }

  prepAction(action) {
    this.submitted = true;
    if (this.selectedItems.length > 0) {
      if (this.product.itemID == "" || this.product.pin == "") {
        this.toast.showWarning("Please scan receipt and enter associate PIN");
      } else {
        if (this.product.itemID.trim()) {
          this.backroomService
            .savePrep(this.product, this.selectedItems, action)
            .then((res) => {
              this.prepDialog = false;
              this.submitted = false;
              this.toast.handleResponse(res);
              this.clearData();
            });
        }
      }
    } else {
      this.toast.showWarning("Please choose any item to prep");
    }
  }

  redirectPage(action) {
    if (action == "ready") {
      router.push({ path: "/backroom/on-ready", params: {} });
    } else if (action == "pending") {
      router.push({ path: "/backroom/on-progress", params: {} });
    } else {
      router.push({ path: "/backroom/on-rack", params: {} });
    }
  }
}
