
import { Options, Vue } from "vue-class-component";
import Announcement from "../../service/Announcement";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import { IAnnounce } from "../announcements/IAnnounce";
import AnnouncementDialog from "../../components/AnnouncementDialog.vue";
@Options({
  components: {
    AnnouncementDialog,
  },
})
export default class Announcements extends Vue {
  private lists = [];
  private announcements;
  private toast;
  private dialogTitle = '';
  private productStatus = "";
  private searchFilter = false;
  private announceStatus = "";
  private statusDialog = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Announcements" },
  ];

  private announce: IAnnounce = {
    annoucementId: "",
    notification: "",
    title: "",
    description: "",
    managerId: "",
    branchId: "",
    annoucementsType: "",
    assignedBranchId: [],
    status: "active",
  };

  private searchFilters = {
    filter: {
      name: "",
      value: "none",
    },
    dateFrom: "",
    dateTo: "",
  };

  private filterTypes = [
    {
      value: "none",
      name: "None",
    },
    {
      value: "Today",
      name: "Today",
    },
    {
      value: "This_Month",
      name: "This Month",
    },
    {
      value: "Last_Month",
      name: "Last Month",
    },
    {
      value: "This_Year",
      name: "This Year",
    },
  ];

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.announcements = new Announcement();
    this.toast = new Toaster();
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.announce.annoucementId = data.annoucement_id;
    this.announce.notification = data.notification;
    this.announce.title = data.title;
    this.announce.description = data.description;
    this.announce.managerId = data.manager_id;
    this.announce.branchId = data.branch_id;
    this.announce.annoucementsType = data.annoucements_type;
    this.announce.assignedBranchId = data.assigned_branch_id;
    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.announce.status = this.productStatus;
    this.announcements.changeStatus(this.announce).then((res) => {
      this.loadList();
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList();
  }

  //FETCH THE DATA FROM SERVER
  loadList() {
    this.searchFilter = false;
    this.announcements.getItems(this.searchFilters).then((data) => {
      this.lists = data.announement_list;
    });
  }

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm").format("hh:mm A");
    }
  }

  openSearchFilter() {
    this.searchFilter = true;
    this.searchFilters = {
      filter: {
        name: "",
        value: "none",
      },
      dateFrom: "",
      dateTo: "",
    };
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.announce.annoucementId = data.annoucement_id;
    this.announceStatus = "updateAnnouncement";
  }

  //USED TO UPDATE THE PIN STATUS
  updateAnnounceStatus() {
    this.announceStatus = "";
    this.loadList();
  }

  //USED TO OPEN THE ADD DIALOG MODEL
  openDialog() {
    this.announceStatus = "openAnnouncement";
  }
}
