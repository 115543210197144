
import { Options, mixins } from "vue-class-component";
import "@/assets/css/backroomStyle.css";
import { useStore } from "../../store";
import Backrooom from "../../service/Backroom";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import PreviewReceipt from "../../components/PreviewReceipt.vue";
import EmployeeBackroom from "../../components/EmployeeBackroom.vue";
import AssocaiteBackroomPickup from "../../components/AssocaiteBackroomPickup.vue";
import CustomerDialog from "../../components/CustomerDialog.vue";
import Customer from "../../service/Customer";
import UtilityOptions from "../../mixins/UtilityOptions";


interface OrderType {
  completedNotification: string;
  cusId: string;
  customer: string;
  dueDate: string;
  dueTime: string;
  express: string;
  expressNotification: string;
  invoiceId: string;
  sDueDate: string;
  sDueTime: string;
  salesId: string;
  serviceTotal: number;
  status: string;
  prodDate: string;
  danger: string;
  notification: string;
  invStatus: string;
  totalBill: number;
}

interface filteredOrderType {
  rDate: string;
  dayTotal: number;
  order: OrderType [];
}

interface WeekDates {
  weekDate: string;
}


@Options({
  components: {
    PreviewReceipt,
    EmployeeBackroom,
    AssocaiteBackroomPickup,
    CustomerDialog,
  },
  title: 'On Progress'
})
export default class OnProgress extends mixins(UtilityOptions) {
  private store = useStore();
  private customerService;
  private previewReceiptStatus = false;
  private receiptID = "";
  private orderType = "";
  private orderStatus = "";
  private orderAction = "";
  private previousWeek = "";
  private totalBill = 0;
  private toast;
  private nextWeek = "";
  private searchDate = "";
  private associateStatus = false;
  private associateOrderPickStatus = false;
  private weekDates: WeekDates [] =  [];
  private orderLists: OrderType [] = [];
  private backroomService;
  private customerId = "";
  private customerStatus = "";
  private customerStoreID = "";
  private customerProfile = [];
  private permission = "";
  private dialogTitle = "";

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.backroomService = new Backrooom();
    this.customerService = new Customer();
    this.toast = new Toaster();
  }

  mounted() {
    const fetchDate = "";
    this.loadList(fetchDate);
  }

  get progressBar() {
    return this.store.getters.getProgressBar;
  }

  loadList(fetchDate) {
    fetchDate = fetchDate.trim();
    this.backroomService.getProgressList(fetchDate).then((data) => {
      this.previousWeek = data.previous_week;
      this.nextWeek = data.next_week;
     this.orderLists = this.camelizeKeys(data.order_list);

      this.weekDates = [];

      //ASSIGNING WEEK DATES 
      this.weekDates.push({
        weekDate: data.week_dates.sunday
      });

      this.weekDates.push({
        weekDate: data.week_dates.monday
      });

      this.weekDates.push({
        weekDate: data.week_dates.tuesday
      });

      this.weekDates.push({
        weekDate: data.week_dates.wednesday
      });

      this.weekDates.push({
        weekDate: data.week_dates.thursday
      });

      this.weekDates.push({
        weekDate: data.week_dates.friday
      });

      this.weekDates.push({
        weekDate: data.week_dates.saturday
      });

      // this.weekDates.push({
      //   weekDate: data.week_dates.next_sunday
      //});

    });
  }

  //USED TO UPDATE THE PIN STATUS
  updateCustomerStatus() {
    this.customerStatus = "";
  }

  openCustomerProfile(id) {
    if (id != "") {
      this.dialogTitle = "Customer Profile";
      this.permission = "yes";
      this.customerStatus = "updateCustomer";
      this.customerId = id;

      this.customerService.getItem(id).then((res) => {
        this.customerProfile = this.camelizeKeys(res);
      });
    }
  }
  nextList() {
    this.searchDate = this.nextWeek;
    this.loadList(this.nextWeek);
  }

  previousList() {
    this.searchDate = this.previousWeek;
    this.loadList(this.previousWeek);
  }

  filterDate() {
    this.loadList(this.searchDate);
  }

  dayName(value) {
    if (value) {
      return moment(String(value)).format("dddd");
    }
  }
  
  get filterOrderList()
  {
    let sortedOrderList: filteredOrderType [] =  [];
      
    this.weekDates.forEach(d => {

      let dayTotal = 0;

      let orderList: OrderType [] = [];

        if(this.orderLists.length > 0)
        {
        this.orderLists.forEach(o => {

          const prodDate = this.getProductionDate(o);

            if(d.weekDate == prodDate)
            {
              dayTotal = dayTotal + o.serviceTotal;

              if(o.express == 'express')
              {
                const danger = this.checkAlertNessOfOrder(prodDate,o.sDueTime);

                orderList.push(
                {
                  completedNotification: o.completedNotification,
                  cusId: o.cusId,
                  customer: o.customer,
                  dueDate: o.sDueDate,
                  dueTime: o.sDueTime,
                  express: o.express,
                  expressNotification: o.expressNotification,
                  invoiceId: o.invoiceId,
                  sDueDate: o.sDueDate,
                  sDueTime: o.sDueTime,
                  salesId: o.salesId,
                  serviceTotal: o.serviceTotal,
                  status: o.status,
                  totalBill: o.totalBill,
                  prodDate: prodDate,
                  danger: danger,
                  notification: o.expressNotification,
                  invStatus: o.invStatus 
                });
              }
              else
              {
                const f =  orderList.filter(e => e.invoiceId == o.invoiceId && e.express == 'no');
    
                if(f.length == 0)
                {
                  const danger = this.checkAlertNessOfOrder(prodDate,o.dueTime);

                  orderList.push(
                  {
                    completedNotification: o.completedNotification,
                    cusId: o.cusId,
                    customer: o.customer,
                    dueDate: o.dueDate,
                    dueTime: o.dueTime,
                    express: o.express,
                    expressNotification: o.expressNotification,
                    invoiceId: o.invoiceId,
                    sDueDate: o.sDueDate,
                    sDueTime: o.sDueTime,
                    salesId: o.salesId,
                    serviceTotal: o.serviceTotal,
                    status: o.status,
                    totalBill: o.totalBill,
                    prodDate: prodDate,
                    danger: danger,
                    notification: o.completedNotification,
                    invStatus: o.invStatus 
                  });
                }
              }
            }
        });
        }

        sortedOrderList.push(
          {
            rDate: d.weekDate,
            order: orderList,
            dayTotal: dayTotal
          }
        );
    });

    return sortedOrderList;
  }

  refreshList() {
    this.loadList(this.searchDate);
  }

  previewReceipt(receiptID) {
    this.previewReceiptStatus = true;
    this.receiptID = receiptID;
  }

  updateReceiptStatus() {
    this.receiptID = "";
    this.previewReceiptStatus = false;
  }

  previewAssocaites(receiptID) {
    this.receiptID = receiptID;
    this.associateStatus = true;
  }

  pickupAssocaiteOrder(receiptID, type, orderStatus, orderAction) {
    this.receiptID = receiptID;
    this.orderType = type;
    this.orderStatus = orderStatus;
    this.orderAction = orderAction;
    this.associateOrderPickStatus = true;
  }

  closePreviewAssocaites() {
    this.associateStatus = false;
  }

  closeupdateOrderStatus() {
    this.associateOrderPickStatus = false;
    this.loadList(this.searchDate);
  }


  getProductionDate(order)
  {
    let prodDate = '';
    const d = moment().format('YYYY-MM-DD');

    if(order.express == 'express')
    {
      prodDate = moment(order.sDueDate).subtract(1, "days").format("YYYY-MM-DD");
    }
    else
    {
      prodDate = moment(order.dueDate).subtract(1, "days").format("YYYY-MM-DD");
    }
     
    
    let receiptDate = '';
    const p = moment(d).isBefore(prodDate);
      
    if(p)
    {
      receiptDate =  prodDate;
    }
    else
    {
      receiptDate = order.dueDate;
    }

    return receiptDate;
  }

  checkAlertNessOfOrder(pD,dueTime)
	{
    const d = moment().format('YYYY-MM-DD');
    
    let response = '';

		if(moment(d).isAfter(pD))
		{
			response =  'over';
		}
		else if(moment(d).isSame(pD))
		{

      let startTime = moment().format("HH:mm");
      let endTime = moment(dueTime, "HH:mm");
      let hours = parseInt(moment.utc(moment(endTime, "HH").diff(moment(startTime, "HH"))).format("HH"));

			if(hours > 3)
			{
				response =  'has_time';
			}
			else
			{
				response =  'over';
			}
		}
		else if(moment(d).isBefore(pD))
		{
			response =  'has_time';
		}

		return response;
	}
}
