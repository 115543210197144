<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>

        <template v-slot:right>
          <Button
            icon="pi pi-plus"
            class="p-mr-2 p-button-success"
            @click="openDialog"
          />
          <Button
            icon="pi pi-search"
            class="p-mr-2 p-button-primary"
            @click="openSearchFilter"
          />
        </template>
      </Toolbar>
      <div class="p-mt-2">
        <DataTable
          :value="lists"
          :lazy="false"
          :paginator="false"
          :scrollable="true"
          class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        >
          <Column
            field="annoucement_id"
            headerStyle="width: 150px"
            header="Announcement ID"
          ></Column>
          <Column
            field="notification"
            headerStyle="width: 150px"
            header="Notification Type"
          ></Column>
          <Column
            field="title"
            headerStyle="width: 300px"
            header="Title"
          ></Column>
          <Column
            field="created_date"
            headerStyle="width: 200px"
            header="Date / Time"
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.created_date) }} /
              {{ formatTime(slotProps.data.time) }}
            </template>
          </Column>
          <Column
            field="employee_firstname"
            headerStyle="width: 200px"
            header="Manager"
          >
            <template #body="slotProps">
              {{ slotProps.data.employee_firstname }}
              {{ slotProps.data.employee_name }} (
              {{ slotProps.data.manager_id }} )
            </template>
          </Column>
          <Column
            field="annoucements_type"
            headerStyle="width: 150px"
            header="Type"
          ></Column>
          <Column
            field="mall"
            headerStyle="width: 300px"
            header="Created Store"
          >
            <template #body="slotProps">
              {{ slotProps.data.mall }} {{ slotProps.data.branch_id }} (
              {{ slotProps.data.is_admin }} )
            </template>
          </Column>
          <Column
            :exportable="false"
            headerStyle="width: 150px"
            header="Action"
          >
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary p-mr-2"
                @click="editIem(slotProps.data)"
              />
              <Button
                v-if="slotProps.data.status == 'active'"
                icon="pi pi-eye-slash"
                class="p-button-rounded p-button-danger"
                @click="confirmChangeStatus(slotProps.data, 'inactive')"
              />
              <Button
                v-else-if="slotProps.data.status == 'inactive'"
                icon="pi pi-eye"
                class="p-button-rounded p-button-warning"
                @click="confirmChangeStatus(slotProps.data, 'active')"
              />
            </template>
          </Column>
        </DataTable>
      </div>

      <Dialog
        v-model:visible="searchFilter"
        :style="{ width: '50vw' }"
        :maximizable="true"
        position="top"
        class="p-fluid"
      >
        <template #header>
          <h4 class="p-dialog-titlebar p-dialog-titlebar-icon">
            Filter Annoncements
          </h4>
        </template>
        <div class="p-field">
          <label for="name">Filter</label>
          <Dropdown
            id="StoreType"
            v-model="searchFilters.filter"
            :options="filterTypes"
            optionLabel="name"
            placeholder="e.g This Month"
          />
        </div>
        <div class="p-field">
          <label for="dateFrom">OR From Date</label>
          <input
            type="date"
            id="basic"
            class="form-control input-lg"
            v-model="searchFilters.dateFrom"
          />
        </div>
        <div class="p-field">
          <label for="dateTo">To Date</label>
          <input
            type="date"
            class="form-control input-lg"
            v-model="searchFilters.dateTo"
          />
        </div>
        <template #footer>
          <Button
            type="submit"
            label="Search"
            icon="pi pi-search"
            class="p-button-primary"
            @click="loadList"
          />
        </template>
      </Dialog>

      <Dialog
        v-model:visible="statusDialog"
        :style="{ width: '450px' }"
        header="Confirm"
      >
        <div class="confirmation-content">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span v-if="announce">
            Change the status of <b>{{ announce.title }}</b> to
            {{ productStatus }}?</span
          >
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text"
            @click="statusDialog = false"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            class="p-button-text"
            @click="changeStatus"
          />
        </template>
      </Dialog>
      <AnnouncementDialog
        :announceDetail="{
          status: announceStatus,
          annoucementId: announce.annoucementId,
        }"
        v-on:updateAnnounceStatus="updateAnnounceStatus"
      />
    </div>
  </section>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Announcement from "../../service/Announcement";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import { IAnnounce } from "../announcements/IAnnounce";
import AnnouncementDialog from "../../components/AnnouncementDialog.vue";
@Options({
  components: {
    AnnouncementDialog,
  },
})
export default class Announcements extends Vue {
  private lists = [];
  private announcements;
  private toast;
  private dialogTitle = '';
  private productStatus = "";
  private searchFilter = false;
  private announceStatus = "";
  private statusDialog = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Announcements" },
  ];

  private announce: IAnnounce = {
    annoucementId: "",
    notification: "",
    title: "",
    description: "",
    managerId: "",
    branchId: "",
    annoucementsType: "",
    assignedBranchId: [],
    status: "active",
  };

  private searchFilters = {
    filter: {
      name: "",
      value: "none",
    },
    dateFrom: "",
    dateTo: "",
  };

  private filterTypes = [
    {
      value: "none",
      name: "None",
    },
    {
      value: "Today",
      name: "Today",
    },
    {
      value: "This_Month",
      name: "This Month",
    },
    {
      value: "Last_Month",
      name: "Last Month",
    },
    {
      value: "This_Year",
      name: "This Year",
    },
  ];

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.announcements = new Announcement();
    this.toast = new Toaster();
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.announce.annoucementId = data.annoucement_id;
    this.announce.notification = data.notification;
    this.announce.title = data.title;
    this.announce.description = data.description;
    this.announce.managerId = data.manager_id;
    this.announce.branchId = data.branch_id;
    this.announce.annoucementsType = data.annoucements_type;
    this.announce.assignedBranchId = data.assigned_branch_id;
    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.announce.status = this.productStatus;
    this.announcements.changeStatus(this.announce).then((res) => {
      this.loadList();
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList();
  }

  //FETCH THE DATA FROM SERVER
  loadList() {
    this.searchFilter = false;
    this.announcements.getItems(this.searchFilters).then((data) => {
      this.lists = data.announement_list;
    });
  }

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm").format("hh:mm A");
    }
  }

  openSearchFilter() {
    this.searchFilter = true;
    this.searchFilters = {
      filter: {
        name: "",
        value: "none",
      },
      dateFrom: "",
      dateTo: "",
    };
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.announce.annoucementId = data.annoucement_id;
    this.announceStatus = "updateAnnouncement";
  }

  //USED TO UPDATE THE PIN STATUS
  updateAnnounceStatus() {
    this.announceStatus = "";
    this.loadList();
  }

  //USED TO OPEN THE ADD DIALOG MODEL
  openDialog() {
    this.announceStatus = "openAnnouncement";
  }
}
</script>