<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>

        <template v-slot:right>
          <div class="p-inputgroup">
            <InputText
              v-model.trim="keyword"
              required="true"
              :class="{ 'p-invalid': submitted && !keyword }"
              placeholder="Search"
            />
            <Button
              icon="pi pi-search "
              class="p-button-primary p-mr-1"
              @click="loadList"
            />
          </div>
          <div class="p-mx-1">
            <Button
              icon="pi pi-plus"
              class="p-mr-2 p-button-success"
              @click="openDialog"
            />
          </div>
          <div class="p-mx-1">
            <Button
              icon="pi pi-bars"
              class="p-mr-2 p-button-warning"
              @click="openBarcodeDialog"
            />
          </div>
        </template>
      </Toolbar>
      <div class="p-mt-2">
        <DataTable
          :value="lists"
          :lazy="false"
          :paginator="false"
          :scrollable="true"
          class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        >
          <Column
            field="employee_id"
            headerStyle="width: 150px"
            header="Associate ID"
          ></Column>
          <Column
            headerStyle="width: 300px"
            header="Name"
          >
           <template #body="slotProps">
              {{  slotProps.data.employee_firstname }}
              {{  slotProps.data.employee_name }}
            </template>
          </Column>
          <Column
            field="user_contact_1"
            headerStyle="width: 200px"
            header="Contact No"
          >
          </Column>
          <Column
            field="hourly_rate"
            headerStyle="width: 100px"
            header="Hourly"
          >
          </Column>
          <Column
            field="magic"
            headerStyle="width: 100px "
            header="Magic"
          ></Column>
          <Column field="mall1" headerStyle="width: 300px" header="Home Store">
          </Column>
          <Column field="mall2" headerStyle="width: 300px" header="Store 2">
          </Column>
          <Column field="mall3" headerStyle="width: 300px" header="Store 3">
          </Column>
          <Column field="status" headerStyle="width: 100px" header="Status">
          </Column>
          <Column
            :exportable="false"
            headerStyle="width: 150px"
            header="Action"
          >
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary p-mr-2"
                @click="editIem(slotProps.data)"
              />
              <Button
                icon="pi pi-key"
                class="p-button-rounded p-button-warning p-mr-2"
                @click="openChangeDialog()"
              />
              <Button
                v-if="slotProps.data.status == 'Active'"
                icon="pi pi-eye-slash"
                class="p-button-rounded p-button-danger"
                @click="confirmChangeStatus(slotProps.data, 'Inactive')"
              />
              <Button
                v-else-if="slotProps.data.status == 'Inactive'"
                icon="pi pi-eye"
                class="p-button-rounded p-button-warning"
                @click="confirmChangeStatus(slotProps.data, 'Active')"
              />
            </template>
          </Column>
        </DataTable>
      </div>

      <Dialog
        v-model:visible="changePinDialog"
        :style="{ width: '50vw' }"
        :maximizable="true"
        position="top"
        class="p-fluid p-m-0 p-dialog-maximized"
      >
        <template #header>
          <h4 class="p-dialog-titlebar p-dialog-titlebar-icon">
            <i class="pi pi-cog"></i> Associate PIN Settings
          </h4>
        </template>
        <div class="p-grid">
          <div class="p-col-6">
            <div class="p-field">
              <h5><i class="pi pi-key"></i> Change Associate PIN</h5>
              <p class="text-danger">Only HQ can change the pin of associate</p>
            </div>
            <div class="p-field">
              <label for="associate_id">Associate ID :</label>
              <InputText
                id="associate_id"
                v-model="associate.employeeId"
                :class="{ 'p-invalid': submitted && !associate.employeeId }"
              />
              <small class="p-invalid" v-if="submitted && !associate.employeeId"
                >Associate ID is required.</small
              >
            </div>
            <div class="p-field">
              <label for="old_pin">Old PIN :</label>
              <input
                type="password"
                id="old_pin"
                class="form-control"
                v-model="associate.oldPin"
              />
            </div>
            <div class="p-field">
              <label for="new_pin">New PIN :</label>
              <input
                type="password"
                id="new_pin"
                class="form-control"
                v-model="associate.newPin"
              />
            </div>
            <div class="p-field">
              <label for="confirm_pin">Confirm PIN :</label>
              <input
                type="password"
                id="confirm_pin"
                class="form-control"
                v-model="associate.confirmPin"
              />
            </div>
            <Button
              type="submit"
              label="Change Pin"
              class="p-button-danger"
              @click="changePin"
            />
          </div>
          <!-- .p-col-6-->
          <div class="p-col-6">
            <div class="p-field">
              <h5><i class="pi pi-eye"></i> Show Associate PIN</h5>
              <p>Only store manager can see the associate pin</p>
            </div>
            <div class="p-field">
              <label for="associate_id_pin">Associate ID :</label>
              <InputText
                id="associate_id_pin"
                v-model="associate.showEmployeeId"
                :class="{ 'p-invalid': showPin && !associate.showEmployeeId }"
              />
              <small
                class="p-invalid"
                v-if="showPin && !associate.showEmployeeId"
                >Associate ID is required.</small
              >
            </div>
            <div class="p-field">
              <label for="store_manager_pin">Store Manager PIN :</label>
              <input
                type="password"
                id="store_manager_pin"
                class="form-control"
                :class="{ 'p-invalid': showPin && !associate.storeManagerPin }"
                v-model="associate.storeManagerPin"
              />
              <small
                class="p-invalid"
                v-if="showPin && !associate.storeManagerPin"
                >Store Manager PIN is required.</small
              >
            </div>
            <div class="p-field">
              <Textarea
                class="form-control bg-light"
                :value="associate.associatePin"
                rows="6"
                cols="30"
              />
            </div>
            <div class="p-field">
              <Button
                type="submit"
                label="Show Pin"
                class="p-button-primary"
                @click="showAssociatePin"
              />
            </div>
          </div>
          <!-- .p-col-6-->
        </div>
      </Dialog>

      <Dialog
        v-model:visible="statusDialog"
        :style="{ width: '450px' }"
        header="Confirm"
      >
        <div class="confirmation-content">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span v-if="associate">
            Change the status of <b>{{ associate.name }}</b> to
            {{ productStatus }}?</span
          >
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text"
            @click="statusDialog = false"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            class="p-button-text"
            @click="changeStatus"
          />
        </template>
      </Dialog>
      <AssociateDialog
        :associateDetail="{
          status: associateStatus,
          employeeId: associate.employeeId,
          permission: permission,
          title: title,
        }"
        v-on:updateAssociateStatus="updateAssociateStatus"
      />
      
      <AssociateBarcodeDialog
        :associateBarcodeDetail="{
          status: associateBarcodeStatus,
          associateList: this.lists,
        }"
        v-on:updateAssociateBarcodeStatus="updateAssociateBarcodeStatus"
      />
    </div>
  </section>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Associate from "../../service/Associate";
import Toaster from "../../helpers/Toaster";
import AssociateDialog from "../../components/AssociateDialog.vue";
import AssociateBarcodeDialog from "../../components/AssociateBarcodeDialog.vue";
@Options({
  components: {
    AssociateDialog,
    AssociateBarcodeDialog,
  },
  title: 'Associates'
})
export default class Associates extends Vue {
  private lists = [];
  private keyword = "";
  private associates;
  private toast;
  private submitted = false;
  private associateBarcodeStatus = false;
  private showPin = false;
  private permission = "yes";
  private title = "Add Associate";
  private productStatus = "";
  private changePinDialog = false;
  private associateStatus = "";
  private statusDialog = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Associates" },
  ];

  private associate = {
    employeeId: "",
    showEmployeeId: 0,
    name: "",
    oldPin: "",
    newPin: "",
    confirmPin: "",
    storeManagerPin: "",
    associatePin: "",
    status: "Active",
  };

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.associates = new Associate();
    this.toast = new Toaster();
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.associate.employeeId = data.employee_id;
    this.associate.name = data.employee_firstname;

    this.productStatus = status;
    this.statusDialog = true;
  }

  //OPEN CHNAGE DIALOG BOX
  openChangeDialog() {
    this.changePinDialog = true;
    this.submitted = false;
  }

  //CHANGE ASSOCIATE PIN
  changePin(e) {
    e.preventDefault();
    this.submitted = true;

    if (this.associate.employeeId) {
      this.associates.changePin(this.associate).then((res) => {
        this.closeDialogBox();
        //SHOW NOTIFICATION
        this.toast.handleResponse(res);
      });
      this.clearAssociatePin();
    }
  }

  //SHOW ASSOCIATE DIALOG BOX
  showAssociatePin(e) {
    this.showPin = true;
    e.preventDefault();
    if (
      this.associate.showEmployeeId != 0 &&
      this.associate.storeManagerPin != ""
    ) {
      this.associates.getAssociatePin(this.associate).then((res) => {
        if (res.msg) {
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        } else {
          this.associate.associatePin = res;
        }
      });
    }
  }

  //CLEAR ASSOCIATE PIN SETTINGS DIALOG BOX
  clearAssociatePin() {
    this.associate.employeeId = "";
    this.associate.showEmployeeId = 0;
    this.associate.oldPin = "";
    this.associate.newPin = "";
    this.associate.confirmPin = "";
    this.associate.name = "";
    this.associate.storeManagerPin = "";
    this.associate.associatePin = "";
    this.associate.status = "";
  }

  closeDialogBox() {
    this.submitted = false;
    this.changePinDialog = false;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.associate.status = this.productStatus;
    this.associates.changeStatus(this.associate).then((res) => {
      this.loadList();
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    // this.loadList();
  }

  //FETCH THE DATA FROM SERVER
  loadList() {
    this.submitted = true;
    if (this.keyword) {
      this.associates.getItems(this.keyword.toLowerCase()).then((data) => {
        this.lists = data.search_result;
      });
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.associate.employeeId = data.employee_id;
    this.associateStatus = "updateAssociate";
    this.title = "Update Associate";
  }

  //USED TO UPDATE THE PIN STATUS
  updateAssociateStatus() {
    this.associateStatus = "";
    this.loadList();
  }
  
  updateAssociateBarcodeStatus() {
    this.associateBarcodeStatus = false;
  }

  //USED TO OPEN THE ADD DIALOG MODEL
  openDialog() {
    this.associateStatus = "openAssociate";
    this.title = "Add Associate";
  }
  
  openBarcodeDialog() {
    this.associateBarcodeStatus = true;
  }
}
</script>