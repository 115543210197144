
import { Options, Vue } from "vue-class-component";
import AttendanceService from "../../service/AttendanceService";
import Toaster from "../../helpers/Toaster";
import Webcam from "webcam-easy";
import moment from "moment";
import { camelCase } from "lodash";
import { branchDetail } from "../../pages/announcements/IAnnounce";

@Options({
  components: {},
})
export default class Attendance extends Vue {
  private webcam;
  private associatePIN = "";
  private attendenceID = "";
  private cameraScreenTile = "Login";
  private cameraScreenAssociate = "Associate Login";
  private lists = [];
  private myStores: Array<branchDetail> = [];
  private attendence;
  private searchDialog = false;
  private previewDailog = false;
  private surveyDailog = false;
  private cameraFound = false;
  private previewPath = "";
  private previousDate = "";
  private nextDate = "";
  private title = "";
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    {
      label: "Attendance",
    },
  ];
  private toast;

  private searchList = {
    filter: "none",
    date1: "",
    date2: "",
    assignedBranchId: [],
  };

  private surveyList = {
    surveyId: "",
    surveyQuestion: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    option5: "",
    answer: "",
  };

  private canvas;
  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.attendence = new AttendanceService();
    this.toast = new Toaster();
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    //WEBCAMERA SETTINGS
    const webcamElement = document.getElementById("webcam");
    const canvasElement = document.getElementById("canvas");

    this.webcam = new Webcam(webcamElement, "user", canvasElement);

    this.startWebCam();
    this.loadList();
  }

  unmounted() {
    this.webcam.stop();
  }

  startWebCam() {
    this.webcam
      .start()
      .then(() => {
        this.cameraFound = true;
      })
      .catch(() => {
        this.cameraFound = false;
        this.toast.showInfo("Couldnt able to find camera");
      });
  }

  takePhoto() {
    const picture = this.webcam.snap();
    return picture;
  }

  logout(slotProps) {
    this.attendenceID = slotProps.data.id;
    this.cameraScreenTile = "Logout";
    this.cameraScreenAssociate =
      slotProps.data.employeeFirstname + " " + slotProps.data.employeeName;
  }

  logOutReq() {
    if (this.attendenceID != "") {
      const picture = this.takePhoto();
      if (picture != "") {
        this.attendence
          .logoutAssociate(this.attendenceID, this.associatePIN, picture)
          .then((res) => {
            this.toast.handleResponse(res);
            this.associatePIN = "";
            this.loadList();
            this.login();
          });
      } else {
        this.toast.showInfo(
          "Couldnt able to snap associate image. Please refresh screen and try again"
        );
      }
    } else {
      this.toast.showInfo("Please choose any associate first");
    }
  }

  loginReq() {
    const picture = this.takePhoto();
    if (picture != "") {
      this.attendence.loginAssociate(this.associatePIN, picture).then((res) => {
        this.toast.handleResponse(res);
        this.associatePIN = "";
        this.loadList();
      });
    } else {
      this.toast.showInfo(
        "Couldnt able to snap associate image. Please refresh screen and  try again"
      );
    }
  }

  login() {
    this.cameraScreenTile = "Login";
    this.cameraScreenAssociate = "Associate Login";
    this.takePhoto();
  }

  //FETCH THE DATA FROM SERVER
  loadList() {
    this.searchDialog = false;

    this.attendence.getAssociate(this.searchList).then((data) => {
      const res = this.camelizeKeys(data);

      this.previousDate = res.previousDate;

      this.nextDate = res.nextDate;

      this.title = res.availableFrom;

      this.lists = res.attendenceList;
    });
  }

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    let d = "";
    if (value) {
      d =  moment(value, "HH:mm").format("hh:mm A");
    }
    return d;
  }

  formatAmount(value) {
    let balance = 0;

    if (value != "") {
      balance = value.toFixed(2);
    }
    return balance;
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  previewImage(imagePath) {
    this.previewDailog = true;
    this.previewPath = imagePath;
  }

  nextList() {
    this.searchList.filter = "none";
    this.searchList.date1 = this.nextDate;
    this.searchList.date2 = this.nextDate;
    this.loadList();
  }

  previousList() {
    this.searchList.filter = "none";
    this.searchList.date1 = this.previousDate;
    this.searchList.date2 = this.previousDate;
    this.loadList();
  }

  opensearchList() {
    this.searchDialog = true;

    this.attendence.getBranchBasic().then((data) => {
      this.myStores = data.branches_list;
    });
  }

  saveSurvey() {
    this.attendence
      .saveSurvey(
        this.associatePIN,
        this.surveyList.answer,
        this.surveyList.surveyId
      )
      .then((res) => {
        this.toast.handleResponse(res);

        if (res.alert == "info") {
          this.availableSurvey();
        }
      });
  }

  availableSurvey() {
    this.attendence.availableSurvey(this.associatePIN).then((res) => {
      if (res == "no_survey") {
        this.surveyDailog = false;
        this.loginReq();
      } else if (res == "invalid_associate_pin") {
        this.surveyDailog = false;
        this.toast.showWarning("Invalid associate PIN");
      } else {
        if (res.length > 0) {
          this.surveyDailog = true;

          const surveyData = this.camelizeKeys(res[0]);

          this.surveyList.surveyId = surveyData.surveyId;
          this.surveyList.option1 = surveyData.option1;
          this.surveyList.option2 = surveyData.option2;
          this.surveyList.option3 = surveyData.option3;
          this.surveyList.option4 = surveyData.option4;
          this.surveyList.option5 = surveyData.option5;
          this.surveyList.surveyQuestion = surveyData.surveyQuestion;
        } else {
          this.toast.showWarning(
            "Something went wrong please try to refresh and re try"
          );
        }
      }
    });
  }
}
