
import { Options , mixins } from "vue-class-component";
import Branch from "../../service/Branch";
import Toaster from "../../helpers/Toaster";
import StorePin from "../../components/StorePin.vue";
import UtilityOptions from "../../mixins/UtilityOptions";

interface IStore {
  address: string;
  bereavmt: string;
  birhtday: string;
  branch_password: string;
  city: string;
  code: number;
  contact: string;
  cost_center: string;
  country: string;
  created_date: string;
  daily_hrs: number;
  date: string;
  default_schedule: string;
  email_address: string;
  enable_email_sms: string;
  gst: number;
  is_admin: string;
  ll: string;
  mall: string;
  meetcon: number;
  overtime: number;
  overtime_rate: number;
  personel: number;
  postalcode: string;
  province: string;
  pst: number;
  rating_desc: string;
  rating_link: string;
  region: string;
  region_code: string;
  region_name: string;
  regular_hrs: number;
  service_date: string;
  sickhrs: number;
  sqft: string;
  stat_hol:number;
  stat_holiday_rate:number;
  status:string;
  statwork:number;
  store_magic:number;
  tax_code: string;
  tax_name: string;
  telephone: string;
  training: number;
  vacations: number;
}

@Options({
  components: {
    StorePin,
  },
})
export default class Branches extends  mixins(UtilityOptions){
  private lists: IStore [] = [];
  private branches;
  private productStatus = "";
  private keyword = "";
  private loading = false;
  private productDialog = false;
  private statusDialog = false;
  private checkPagination = true;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Stores" },
  ];
  private storeTypes = [
    {
      value: "HQ",
      name: "HQ",
    },
    {
      value: "Branch",
      name: "Store",
    },
  ];
  private taxCriteria = [
    {
      name: "None",
    },
    {
      name: "GST",
    },
    {
      name: "PST",
    },
    {
      name: "HST",
    },
    {
      name: "QST",
    },
    {
      name: "SALES TAX",
    },
  ];

  private smsOptions = [
    {
      value: "enable",
      name: "Enable",
    },
    {
      value: "disable",
      name: "Disable",
    },
  ];

  private toast;

  private provinceList = [
    { key: "Nunavut" },
    { key: "Quebec" },
    { key: "Northwest Territories" },
    { key: "Ontario" },
    { key: "British Columbia" },
    { key: "Alberta" },
    { key: "Saskatchewan" },
    { key: "Yukon" },
    { key: "Newfoundland and Labrador" },
    { key: "New Brunswick" },
    { key: "Nova Scotia" },
    { key: "Alabama" },
    { key: "Alaska" },
    { key: "Arizona" },
    { key: "Arkansas" },
    { key: "California" },
    { key: "Colorado" },
    { key: "Connecticut" },
    { key: "Delaware" },
    { key: "Florida" },
    { key: "Georgia" },
    { key: "Hawaii" },
    { key: "Idaho" },
    { key: "Illinois" },
    { key: "Indiana" },
    { key: "Iowa" },
    { key: "Kansas" },
    { key: "Kentucky" },
    { key: "Louisiana" },
    { key: "Maine" },
    { key: "Maryland" },
    { key: "Massachusetts" },
    { key: "Michigan" },
    { key: "Minnesota" },
    { key: "Manitoba" },
    { key: "Mississippi" },
    { key: "Missouri" },
    { key: "Montana" },
    { key: "Nebraska" },
    { key: "Nevada" },
    { key: "New Hampshire" },
    { key: "New Jersey" },
    { key: "New Mexico" },
    { key: "New York" },
    { key: "North Carolina" },
    { key: "North Dakota" },
    { key: "Ohio" },
    { key: "Oklahoma" },
    { key: "Oregon" },
    { key: "Pennsylvania" },
    { key: "Rhode Island" },
    { key: "South Carolina" },
    { key: "South Dakota" },
    { key: "Tennessee" },
    { key: "Texas" },
    { key: "Utah" },
    { key: "Vermont" },
    { key: "Virginia" },
    { key: "Washington" },
    { key: "West Virginia" },
    { key: "Wisconsin" },
    { key: "Wyoming" },
  ];


  private branchDetails = {
    storeID: 0,
    isAdmin: {},
    storeCode: 0,
    mallName: "",
    telephoneNo: "",
    contactNo: "",
    address: "",
    city: "",
    region: "",
    province: "",
    country: "",
    postalCode: "",
    ll: "",
    costCenter: "",
    regularWeeklyHrs: 44,
    breakHrs: 5,
    regularDailyHrs: 8,
    statholidayRate: 0,
    overTimeRate: 0,
    storeOpeningDate: "",
    sqft: 80,
    taxCriteria1: "None",
    taxCriteria2: "None",
    taxValue1: 0,
    taxValue2: 0,
    emailAddress: "",
    password: "12345",
    taxNo: "",
    overTime: 0,
    birthDay: 0,
    statHoliday: 0,
    statWork: 0,
    meetCon: 0,
    personal: 0,
    sickHrs: 0,
    vacations: 0,
    breavmt: 0,
    training: 0,
    storeMagic: 0,
    enableSms: {},
    status: "",
    qrCodeLink: "",
    qrCodeTagLine: "",
  };

  private dialogTitle;
  private dialogCallback;
  private regionsList;
  private submitted = false;
  private pinDialogStatus = false;
  private storeCode = 0;

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.branches = new Branch();
    this.toast = new Toaster();
  }

  //OPNE DIALOG TO CHANGE STORE PIN
  changePin(data) {
    this.pinDialogStatus = true;
    this.storeCode = Number(data.code);
  }

  //USED TO UPDATE THE PIN STATUS
  updateThePinDialogStatus() {
    this.pinDialogStatus = false;
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.clearItem();

    this.submitted = false;
    this.dialogTitle = "Add New Store";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem() {
    this.submitted = true;
    if (this.branchDetails.mallName.trim()) {
      if (this.branchDetails.storeID != 0) {
        this.branches.updateItem(this.branchDetails).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.branches.saveItem(this.branchDetails).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    
    this.submitted = false;
    this.dialogTitle = "Update Store";
    this.productDialog = true;
    this.branches.getItem(data).then((res) => {
      if (res.length > 0) {
        res = res[0];

        const isAdmin = this.filterStoreType(res);
        const smsEnabled = this.filterSmsEnabled(res);

        this.branchDetails = {
          isAdmin: isAdmin,
          storeID: Number(res.code),
          storeCode: Number(res.code),
          mallName: res.mall,
          telephoneNo: res.telephone,
          contactNo: res.contact,
          address: res.address,
          city: res.city,
          region: res.region,
          province: res.province,
          country: "",
          postalCode: res.postalcode,
          ll: res.ll,
          costCenter: res.cost_center,
          breakHrs: Number(res.break_hrs),
          regularWeeklyHrs: Number(res.regular_hrs),
          regularDailyHrs: Number(res.daily_hrs),
          statholidayRate: Number(res.stat_holiday_rate),
          overTimeRate: Number(res.overtime_rate),
          storeOpeningDate: res.service_date,
          sqft: Number(res.sqft),
          taxCriteria1: res.tax_criteria_1,
          taxCriteria2: res.tax_criteria_2,
          taxValue1: Number(res.tax_value_1),
          taxValue2: Number(res.tax_value_2),
          emailAddress: res.email_address,
          password: res.branch_password,
          taxNo: res.tax_code,
          overTime: Number(res.overtime),
          birthDay: Number(res.birhtday),
          statHoliday: Number(res.stat_hol),
          statWork: Number(res.statwork),
          meetCon: Number(res.meetcon),
          personal: Number(res.personel),
          sickHrs: Number(res.sickhrs),
          vacations: Number(res.vacations),
          breavmt: Number(res.bereavmt),
          training: Number(res.training),
          storeMagic: Number(res.store_magic),
          enableSms: smsEnabled,
          status: res.status,
          qrCodeLink: res.rating_link,
          qrCodeTagLine: res.rating_desc
        };
      }
    });
  }

  //USED TO FILTER TYPES
  filterStoreType(res) {
    let isAdmin = {};

    this.storeTypes.filter((elem) => {
      if (elem.value == res.is_admin) {
        isAdmin = elem;
      }
    });

    return isAdmin;
  }


  //USED TO FILTER TAX
  filterSmsEnabled(res) {
    let smsEnabled = {};
    this.smsOptions.filter((elem) => {
      if (elem.value == res.enable_email_sms) {
        smsEnabled = elem;
      }
    });

    return smsEnabled;
  }

  //USED TO FILTER TAX
  filterRegion(res) {
    let region = {};

    this.regionsList.filter((elem) => {
      if (elem.region_id == res.province) {
        region = elem;
      }
    });

    return region;
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.branchDetails = {
      isAdmin: data.is_admin,
      storeID: Number(data.code),
      storeCode: Number(data.code),
      mallName: data.mall,
      telephoneNo: data.telephone,
      contactNo: data.contact,
      address: data.address,
      city: data.city,
      region: data.region_id,
      province: data.province_name,
      country: data.country,
      postalCode: data.postalcode,
      ll: data.ll,
      costCenter: data.cost_center,
      regularWeeklyHrs: Number(data.regular_hrs),
      regularDailyHrs: Number(data.daily_hrs),
      breakHrs: Number(data.break_hrs),
      statholidayRate: Number(data.stat_holiday_rate),
      overTimeRate: Number(data.overtime_rate),
      storeOpeningDate: data.service_date,
      sqft: Number(data.sqft),
      taxCriteria1: data.tax_criteria_1,
      taxCriteria2: data.tax_criteria_2,
      taxValue1: Number(data.tax_value_1),
      taxValue2: Number(data.tax_value_2),
      emailAddress: data.email_address,
      password: data.branch_password,
      taxNo: data.tax_code,
      overTime: Number(data.overtime),
      birthDay: Number(data.birhtday),
      statHoliday: Number(data.stat_hol),
      statWork: Number(data.statwork),
      meetCon: Number(data.meetcon),
      personal: Number(data.personel),
      sickHrs: Number(data.sickhrs),
      vacations: Number(data.vacations),
      breavmt: Number(data.bereavmt),
      training: Number(data.training),
      storeMagic: Number(data.store_magic),
      enableSms: data.enable_email_sms,
      status: data.status,
      qrCodeLink: data.rating_link,
      qrCodeTagLine: data.rating_desc
    };

    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.branchDetails.status = this.productStatus;
    this.branches.changeStatus(this.branchDetails).then((res) => {
      this.loadList(0);
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.loading = true;
    this.branches.getItems(page).then((res) => {
      this.lists        = res.store_list;
      this.totalRecords = res.total_stores_count;
      this.limit        = res.limit_per_page;
      this.regionsList  = res.regions;
      this.loading      = false;
    });
  }

  loadSearchData() {
    this.submitted = true;
    if (this.keyword) {
      this.branches.getSearchedBranches(this.keyword).then((data) => {
        this.lists = data.store_list;
        this.loading = false;
        this.checkPagination = data.pagination;
      });
    }
  }

  clearItem() {
    this.branchDetails = {
      storeID: 0,
      isAdmin: {},
      storeCode: 0,
      mallName: "",
      telephoneNo: "",
      contactNo: "",
      address: "",
      city: "",
      region: "",
      province: "",
      country: "",
      postalCode: "",
      ll: "",
      costCenter: "",
      regularWeeklyHrs: 44,
      regularDailyHrs: 8,
      breakHrs: 0,
      statholidayRate: 0,
      overTimeRate: 0,
      storeOpeningDate: "",
      sqft: 80,
      taxCriteria1: "None",
      taxCriteria2: "None",
      taxValue1: 0,
      taxValue2: 0,
      emailAddress: "",
      password: "12345",
      taxNo: "",
      overTime: 0,
      birthDay: 0,
      statHoliday: 0,
      statWork: 0,
      meetCon: 0,
      personal: 0,
      sickHrs: 0,
      vacations: 0,
      breavmt: 0,
      training: 0,
      storeMagic: 0,
      enableSms: {},
      status: "",
      qrCodeLink: "",
      qrCodeTagLine: "",
    };
  }

  taxCriteriaSetting1()
  {
    this.branchDetails.taxValue1 = 0;
  }
  
  taxCriteriaSetting2()
  {
    this.branchDetails.taxValue2 = 0;  
  }

  convertName(t)
  {
    let n = t;

    if(t == 'Branch')
    {
      n = 'Store';
    }

    return n;
  }

  get sortStores()
  {
     const c = this.lists.sort((a, b) =>
        Number(a.code) < Number(b.code) ? -1 : 1
    );

    return c;
  }
}
